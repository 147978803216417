import Component from '../../../../../libs/components/component';
import { getMyConadApiProvider } from '../../../../../libs/myconad-api-provider';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import './style.scss';

export default class StrilloCollection extends Component {
    constructor(name, root) {
        super(name, root);
        this.root = root;

        this.userService = getUserService();
        this.ctasViewBollini = this._dEl('ctaViewBollini', true);
        this.ctasLogin = this._dEl('ctaLogin', true);
        this.balanceLabels = this._dEl('balanceLabel', true);

        this.balanceContents = this._dEl('balanceContent', true);
        this.balance = this._dEl('balance', true);
        this._doLogic();
    }

    async _doLogic() {
        // if not logged, show login button
        const user = await this.userService.getUser();
        if (!user || !user.cartaFedelta) {
            this.ctasLogin.forEach((ctaLogin) => ctaLogin.classList.remove('!hidden'));
            return;
        }

        let balanceI = 0;
        let inTarget = false;
        try {
            const myConadApiProvider = getMyConadApiProvider();
            const bolliniBalance = await myConadApiProvider.getUserBalance({
                fidelityCard: user.cartaFedelta,
                collectionCode: this.root.dataset.initiativeCode,
            });
            if (bolliniBalance) {
                balanceI = bolliniBalance.saldoCumulato - bolliniBalance.saldoSpeso;
                inTarget = true;
            }
        } catch (e) {
            console.warn(e);
        }

        // don't show cta bollini if not in target
        if (inTarget) {
            this.ctasViewBollini.forEach((ctaViewBollini) => ctaViewBollini.classList.remove('!hidden'));
        }

        // set balance
        this.balance.forEach((balance) => {
            balance.innerHTML = balanceI;
        });
        this.balanceLabels.forEach((balanceLabel) => {
            balanceLabel.classList.remove('hidden');
            balanceLabel.classList.add('flex');
        });
        this.balanceContents.forEach((balanceContent) => {
            balanceContent.classList.remove('hidden');
            balanceContent.classList.add('flex');
        });
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt160-strillo-collection');
}
