import './style.scss';
import Component from '../../../../../libs/components/component';
import { getUserService } from '../../../../../libs/user-service';
import { getMyConadApiProvider } from '../../../../../libs/myconad-api-provider';
import { getFlowManager } from '../../../../../libs/flow-manager';

export default class HeroPremio extends Component {
    constructor(name, root) {
        super(name, root);
        this.root = root;
        this.textColor = this.root.dataset.textColor;

        if (this.textColor) this._setTextColor();

        this.userService = getUserService();
        this.flowManager = getFlowManager();

        this.bolliniValue = this.root.dataset.bolliniValue;
        this.suitableNameplate = this._dEl('suitable');
        this.cta = this._dEl('cta');
        this._showSuitableNameplate();
    }

    async _showSuitableNameplate() {
        const user = await this.userService.getUser();
        if (!user || !user.cartaFedelta) {
            return;
        }

        try {
            const myConadApiProvider = getMyConadApiProvider();
            const bolliniBalance = await myConadApiProvider.getUserBalance({ fidelityCard: user.cartaFedelta, collectionCode: this.root.dataset.initiativeCode });

            const coopsToExclude = this.root.dataset.coopsToExclude ? this.root.dataset.coopsToExclude.split(',') : [];
            if (!coopsToExclude.includes(user.newMappedCooperative.toLowerCase())) {
                this.cta.classList.remove(this._elMod('cta', 'hidden'));
                this._addListener('click', () => {
                    this.flowManager.startFlow({
                        name: 'open-redeem-prize-popup',
                        flowSteps: [{ name: 'redeem-prize-details-popup' }],
                        initialData: {
                            ean1: this.root.dataset.ean1,
                            ean2: this.root.dataset.ean2,
                            descrizioneMetodoRedenzione1: this.root.dataset.bolliniText,
                            descrizioneMetodoRedenzione2: this.root.dataset.pointsText
                        }
                    });
                }, this.cta);
            }

            const totalBalance = bolliniBalance.saldoCumulato - bolliniBalance.saldoSpeso;
            if (totalBalance >= 1) {
                if (this.bolliniValue && this.bolliniValue <= totalBalance) {
                    this.suitableNameplate.classList.remove(this._elMod('suitable', 'invisible'));
                }
            }
        } catch (e) {
            console.warn(e);
        }
    }

    _setTextColor() {
        const color = `${this.textColor}`;
        const textElements = [
            this._dEl('breadcrumb'),
            this._dEl('title'),
            this._dEl('abstract'),
            this._dEl('bolliniText'),
            this._dEl('pointsLabel'),
            this._dEl('pointsText'),
        ];
        for (const el of textElements.filter((el) => !!el)) {
            el.style.color = color;
        }
        const divider = this._dEl('divider');
        if (!divider) return;
        divider.style.backgroundColor = color;
    }
}
