import './style.scss';
import Component from '../../../../../libs/components/component';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';
import { getUserService } from '../../../../../libs/user-service';
import { getMyConadApiProvider } from '../../../../../libs/myconad-api-provider';

export default class CardPremiSlider extends Component {
    constructor(name, root) {
        super(name, root);

        this.pagination = this._dEl('pagination');
        this.swiper = null;

        this.slide = this._dEl('slideContainer');
        this.track = this._dEl('track', false);


        this.userService = getUserService();
        this.cards = this._dEl('slide', true);
        this._initSwiper();
        this._doLogic();
    }


    async _doLogic() {
        const user = await this.userService.getUser();
        if (!user || !user.cartaFedelta) {
            return;
        }

        try {
            const myConadApiProvider = getMyConadApiProvider();
            const bolliniBalance = await myConadApiProvider.getUserBalance({ fidelityCard: user.cartaFedelta, collectionCode: this.root.dataset.initiativeCode });
            const totalBalance = bolliniBalance.saldoCumulato - bolliniBalance.saldoSpeso;
            if (totalBalance >= 1) {
                this.cards.forEach((card) => {
                    if (
                        card.dataset.bolliniValue &&
                        card.dataset.bolliniValue <= totalBalance
                    ) {
                        card.querySelector('.rt218-card-premi__suitable').classList.remove('rt218-card-premi__suitable--invisible');
                    }
                });
            }
        } catch (e) {
            console.warn(e);
        }
    }

    async _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;
        if (this.pagination && slideCounts <= 4) {
            this.pagination.classList.add(this._elMod('pagination', 'hidden'));
        }

        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            watchOverflow: true,
            observer: true,
            observeParents: true,
        };

        if (this.pagination) {
            options['pagination'] = {
                el: this._dEl('pagination', true),
                bulletClass: this._el('bullet'),
                bulletActiveClass: this._elMod('bullet', 'active'),
                clickable: true,
            };
        }
        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.root, options);
    }

    //delete all
    dispose() {
        super.dispose();
        this.swiper?.destroy(true, true);
    }
}


