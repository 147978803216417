import { getApiProvider } from './api-provider';
import { getUserService } from './user-service';
import { Mutex } from 'async-mutex';

export class InsurancesManager {
    constructor() {
        this.apiProvider = getApiProvider();
        this.userService = getUserService();

        this.loginLink = window.loginLink;
        this.cardRequestLink = window.cardRequestLink;
        this.viewInsurancesLink = window.viewInsurancesLink;
        this.policyActivationFormLink = window.policyActivationFormLink;
        this.carrelloProtettoPolicyName = window.carrelloProtettoPolicyName;
        this.lock = new Mutex();
        this.informations = null;
    }

    async _internalGetInsuranceInformations() {
        const user = await this.userService.getUser();
        if (!user) {
            return {
                status: 'guest',
            };
        }

        if (!user.cartaFedelta) {
            return {
                status: 'light',
            };
        }

        // get active policies
        try {
            const policyStatus = await this.apiProvider.getPolicyStatus(user.cartaFedelta);
            if (policyStatus.active) {
                return { status: 'policyAlreadyActive' };
            }
        } catch (e) {
            console.warn('Failed get insurance products, skipped check', e);
        }

        // get scontrino
        try {
            const scontrino = await this.apiProvider.getScontrino();
            if (!scontrino) {
                return { status: 'noReceipts' };
            }
            if (!scontrino.date) {
                return { status: 'noReceipts' };
            }

            return {
                status: 'logged',
                user: user,
                scontrino: scontrino,
            };
        } catch (e) {
            return { status: 'noReceipts' };
        }
    }

    async getInsurancesInformation() {
        const release = await this.lock.acquire();
        try {
            // return saved informations
            if (this.informations) {
                return this.informations;
            }

            // load informations
            this.informations = await this._internalGetInsuranceInformations();
            return this.informations;
        } finally {
            release();
        }
    }

    async getLink() {
        const insurancesInformation = await this.getInsurancesInformation();
        if (insurancesInformation.status === 'guest') {
            const loginLinkWithCallback = this.loginLink + '?cb=' + encodeURIComponent(this.policyActivationFormLink);
            return {
                status: insurancesInformation.status,
                label: 'Login and go to activation',
                url: loginLinkWithCallback,
            };
        }
        if (insurancesInformation.status === 'light') {
            return {
                status: insurancesInformation.status,
                label: 'Associate or request card',
                url: this.cardRequestLink,
            };
        }
        if (insurancesInformation.status === 'policyAlreadyActive') {
            return { status: insurancesInformation.status, label: 'View active policy', url: this.viewInsurancesLink };
        }

        return {
            status: insurancesInformation.status,
            label: 'Go to activation',
            url: this.policyActivationFormLink || '#',
        };
    }
}

/**
 * Get the current InsuranceManager
 * @returns {InsuranceManager}
 */
export const getInsurancesManager = () => {
    if (!window.rcInsuranceManager) {
        window.rcInsuranceManager = new InsurancesManager();
    }
    return window.rcInsuranceManager;
};
