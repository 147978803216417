import axios from 'axios';

export class ApiProvider {
    constructor() {
        const serviceLang = document.documentElement.dataset.serviceLang || 'it-it';
        this.jsonLoader = document.documentElement.dataset.jsonLoader == 'true';
        this.baseUrl = `/api/corporate/${serviceLang}`;

        this.urlMap = {
            all: this.baseUrl + '.%%.json',
            regolamentolink: this.baseUrl + '.regolamentolink.pdf',
            privacyLink: this.baseUrl + '.privacylink.pdf',
        };

        this.cached = {};
    }

    async user() {
        return await this._doGet(this._getEndpoint('user'));
    }

    /**
     * Get informativa privacy for cooperativa
     * @param {*} param0
     * @returns
     */
    async getInformativa({ cooperativa, ecommerce, card }) {
        const data = {
            cooperativa: cooperativa?.toLowerCase(),
            ecommerce: ecommerce,
            card: card ? 'true' : 'false',
        };
        return await this._doCachedGet(this._getEndpoint('informativa'), data);
    }

    /**
     * Get store flyers
     * @param {*} anacanId The anacanId of the store
     * @returns
     */
    async getStoreFlyers(anacanId) {
        const data = {
            anacanId,
        };
        return await this._doGet(this._getEndpoint('flyers'), data);
    }

    /**
     * Associate complementary data.
     * @param {*} param0
     * @returns
     */
    async associateComplementaryData(path) {
        return await this._doPost(this._getEndpoint('associateComplementaryData'), { path });
    }

    /**
     * Remove complementary data.
     * @param {*} param0
     * @returns
     */
    async removeComplementaryData(id) {
        return await this._doPost(this._getEndpoint('removeComplementaryData'), { id });
    }

    /**
     * GET complementary data.
     * @param {*} param0
     * @returns
     */
    async getComplementaryData() {
        return await this._doGet(this._getEndpoint('getComplementaryData'));
    }

    /**
     *
     * @param {*} param0
     * @returns
     */
    async setPreferredStore({ anacanId }) {
        const payload = {
            anacanId: anacanId,
        };
        return await this._doPost(this._getEndpoint('setPreferredStore'), payload);
    }

    /*    */ /**
     *
     * @param {*} choice
     * @returns
     */ /*
    async setCouponChoice(choice) {
        const payload = {
            choice: choice
        };
        return await this._doPost(this._getEndpoint('setCouponChoice'), payload);
    }*/

    /**
     *
     * @param {*} anacanId
     * @returns
     */
    async setSavedStore(anacanId) {
        const payload = {
            anacanId: anacanId,
        };
        return await this._doPost(this._getEndpoint('setSavedStore'), payload);
    }

    /**
     *
     * @param {*} anacanId
     * @returns
     */
    async removeSavedStore(anacanId) {
        const payload = {
            anacanId: anacanId,
        };
        return await this._doPost(this._getEndpoint('removeSavedStore'), payload);
    }

    /**
     * Send customer care request.
     * @param {*} data FormData
     * @returns
     */
    async customerCare(data) {
        return await this._doMultipartPost(this._getEndpoint('customerCare'), data);
    }

    /**
     * Send policy activation request
     * @param {*} data FromData
     * @returns
     */
    async policyActivation(data) {
        return await this._doPost(this._getEndpoint('policyActivation'), data, null, { unwrap: false });
    }

    /**
     * Get carrello protetto policy status
     * @param {*} data cartaFedelta
     * @returns
     */
    async getPolicyStatus(cartaFedelta) {
        const data = {
            cartaFedelta: cartaFedelta,
        };
        return await this._doGet(this._getEndpoint('checkPolicyStatus'), data);
    }

    /**
     * Send pin code request.
     * @param {*} param0
     * @returns
     */
    async sendPinCode(data) {
        return await this._doPost(this._getEndpoint('sendPinCode'), data);
    }

    async getFlyerProductById(productId = '', coopId = '') {
        const data = {
            productId,
            coopId,
        };
        return await this._doGet(this._getEndpoint('getDisProductById'), data);
    }

    async addDisFavoriteProduct(anacanId, disaggregatedId, productId) {
        const payload = {
            anacanId: anacanId,
            volantino: disaggregatedId,
            productId: productId,
        };
        return await this._doPost(this._getEndpoint('setDisProduct'), payload);
    }

    async removeDisProduct(idProdottoNegozioUtente) {
        const payload = {
            idProdottoNegozioUtente,
        };
        return await this._doPost(this._getEndpoint('removeDisProduct'), payload);
    }

    async getDisProduct(anacanId) {
        return await this._doPost(this._getEndpoint('getDisProduct'), {
            anacanId,
        });
    }
    /**
     * Retrieve single point of service by anacanId.
     * @param {*} param0
     * @returns
     */
    async getPointOfServiceByAnacanId({ anacanId }) {
        const data = {
            anacanId,
        };
        return await this._doCachedGet(this._getEndpoint('getPointOfServiceByAnacanId'), data);
    }

    /**
     * Retrieve points of service with filters.
     * @param {*} param0 (insegneId, serviziId, repartiId are String arrays)
     * @returns
     */
    async retrievePointOfService({
        latitudine,
        longitudine,
        raggioRicerca,
        insegneId,
        serviziId,
        repartiId,
        apertura,
    }) {
        const data = {
            latitudine,
            longitudine,
            raggioRicerca,
            insegneId,
            serviziId,
            repartiId,
            apertura,
        };
        return await this._doPost(this._getEndpoint('retrievePointOfService'), data);
    }

    /**
     * Get province.
     * @returns
     */
    async province() {
        const province = await this._doCachedGet(this._getEndpoint('province'));
        return province.map((item) => ({
            selected: false,
            label: item.nome,
            value: item.codice,
        }));
    }

    async getPlaysComp() {
        return await this._doPost(this._getEndpoint('playsCompetition'));
    }

    /**
     * Get Firma digitale
     */
    async getFirmaDigitale() {
        const data = await this._doPost(this._getEndpoint('getFirmaDigitale'), {});
        return data && data.firmaDigitale ? data.firmaDigitale : '';
    }

    /**
     * Edit user data
     * @param {*} param0
     * @returns
     */
    async editUserData({
        nome,
        cognome,
        stato,
        password,
        dataNascita,
        comuneNascita,
        sesso,
        telefonoCellulare,
        telefonoFisso,
        indirizzo,
        numeroCivico,
        cap,
        citta,
        codiceProvincia,
        codiceFiscale,
        dataNascitaFiglio1,
        dataNascitaFiglio2,
        dataNascitaFiglio3,
        privacy1,
        privacy2,
        privacy3,
        privacyTZ,
        numeroComponentiNucleoFamiliare,
        dataNascitaConiuge,
        animaliDomestici,
        abitudiniAlimentariFamiglia,
        hobbyFamiglia,
        acquistiOnline,
        preferenzeComunicazioneNewsletter,
        preferenzeComunicazioneSoloPerTe,
        accessoMinisito,
        invioMailEstrattoContoConadCard,
        rendicontazioneConadCard,
        attivazioneServizioOnlineConadCard,
        versioneInformativaPrivacy,
        mailOcdb,
        emailOCDB,
        firmaDigitale,
        codiceCitta,
        codiceComuneNascita,
        codiceNazione,
        canaliDiContatto,
        negozioPreferito,
    }) {
        const data = {
            nome,
            cognome,
            stato,
            password,
            dataNascita,
            comuneNascita,
            sesso,
            telefonoCellulare,
            telefonoFisso,
            indirizzo,
            numeroCivico,
            cap,
            citta,
            codiceProvincia,
            codiceFiscale,
            dataNascitaFiglio1,
            dataNascitaFiglio2,
            dataNascitaFiglio3,
            privacy1,
            privacy2,
            privacy3,
            privacyTZ,
            numeroComponentiNucleoFamiliare,
            dataNascitaConiuge,
            animaliDomestici,
            abitudiniAlimentariFamiglia,
            hobbyFamiglia,
            acquistiOnline,
            preferenzeComunicazioneNewsletter,
            preferenzeComunicazioneSoloPerTe,
            accessoMinisito,
            invioMailEstrattoContoConadCard,
            rendicontazioneConadCard,
            attivazioneServizioOnlineConadCard,
            versioneInformativaPrivacy,
            mailOcdb,
            emailOCDB,
            firmaDigitale,
            codiceCitta,
            codiceComuneNascita,
            codiceNazione,
            canaliDiContatto,
            negozioPreferito,
        };
        return await this._doPost(this._getEndpoint('editUserData'), data);
    }

    /**
     * Change user account email for refresh campaign
     * @param {*} param0
     * @returns
     */
    async changeEmailRequestV2({ email, updateGccMail }) {
        const data = {
            email,
            updateGccMail,
        };
        return await this._doPost(this._getEndpoint('changeEmailRequestV2'), data);
    }

    async getScontrino() {
        return await this._doGet(this._getEndpoint('scontrino'));
    }

    async getUserBalance({ collectionCode, fidelityCard }) {
        const params = {
            fidelityCard,
            collectionCode,
        };
        return await this._doCachedGet(this._getEndpoint('userBalance'), params);
    }

    /**
     * Return the regolamento link
     * @param {*} coop The cooperative code
     * @returns
     */
    getRegolamentoLink(coop) {
        return `${this._getEndpoint('regolamentolink')}?coop=${coop.toLowerCase()}`;
    }

    _getEndpoint(name) {
        const url = this.urlMap[name];
        if (url) return url;
        return this.urlMap['all'].replace('%%', name);
    }

    /**
     * Do HTTP GET for loader.
     * Internally uses _doTextGet & _doGet
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async loaderGet(endpoint, queryParams) {
        if (this.jsonLoader) {
            // replace selector and extension
            let split = endpoint.split('.');
            split.splice(1, 0, 'jloader');
            const newEndpoint = split.join('.').replace('.html', '.json');
            const result = await this._doGet(newEndpoint, queryParams);
            return result.html;
        } else {
            return await this._doTextGet(endpoint, queryParams);
        }
    }

    /**
     * Do HTTP GET for text content.
     * Internally uses _doTextGet
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async textGet(endpoint, queryParams) {
        return await this._doTextGet(endpoint, queryParams);
    }

    /**
     * Do HTTP GET with response type "text"
     * Used for download html content
     * @param {*} url
     * @param {*} queryParams
     * @returns
     */
    async _doTextGet(endpoint, queryParams) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios({
                method: 'get',
                url: endpoint,
                responseType: 'text',
                params: params,
            });
            return result?.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download page';
        }
    }

    async _doMultipartPost(endpoint, body = {}, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doBlobPost(endpoint, body = {}, queryParams) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                params,
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return result.data;
        } catch (error) {
            console.warn(error);
            throw 'Cannot download blob';
        }
    }

    async _doPost(endpoint, body = {}, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = new URLSearchParams(queryParams);
            const result = await axios.post(endpoint, body, {
                withCredentials: true,
                params,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    async _doCachedGet(endpoint, queryParams, { unwrap } = { unwrap: true }, failSilently = false) {
        const params = new URLSearchParams(queryParams);
        const paramsUrl = params.toString();
        const key = endpoint + '?' + paramsUrl;

        const loc = window.localStorage?.getItem(key);
        if (loc) {
            const deserialized = JSON.parse(loc);
            if (deserialized && deserialized.update > Date.now() - 300000) {
                return deserialized.data;
            }
        }

        // if a promise already running, wait on it
        if (this.cached[key]) return await this.cached[key];

        this.cached[key] = this._doGet(endpoint, queryParams, { unwrap }, failSilently);
        const data = await this.cached[key];
        window.localStorage?.setItem(key, JSON.stringify({ update: Date.now(), data }));
        setTimeout(() => {
            this.cached[key] = null; // clean promises
        }, 100);
        return data;
    }

    async _doGet(endpoint, queryParams, { unwrap } = { unwrap: true }) {
        try {
            const params = this._paramsToURLParams(queryParams);
            const result = await axios.get(endpoint, {
                withCredentials: true,
                params: params,
            });
            if (unwrap) {
                return result?.data?.data;
            } else {
                return result?.data;
            }
        } catch (error) {
            console.warn(error);
            if (unwrap) {
                throw error?.response?.data?.data;
            } else {
                throw error?.response?.data;
            }
        }
    }

    _paramsToURLParams(queryParams) {
        if (!queryParams) return new URLSearchParams();

        let urlParams = new URLSearchParams();
        //Ensure the url encoding is performed correctly for arrays
        Object.entries(queryParams).forEach(([param, value]) => {
            if (value)
                if (Array.isArray(value)) value.forEach((item) => urlParams.append(param, item));
                else urlParams.append(param, value);
        });
        return urlParams;
    }
}

/**
 *
 * @returns {ApiProvider} api provider
 */
export const getApiProvider = () => {
    if (!window.rcApiProvider) {
        window.rcApiProvider = new ApiProvider();
    }
    return window.rcApiProvider;
};
