import { flowManager } from '../../../../../libs/flow-manager';

export default function rt307() {
    return {
        premio: {},
        cards: [],
        twoEan: false,
        init() {},
        before() {
            // load premio
            this.premio = flowManager.getDataFromFlow();

            // set cards
            this.twoEan = !!this.premio.ean1 && !!this.premio.ean2;
            this.cards = [];
            if (this.premio.ean1)
                this.cards.push({ ean: this.premio.ean1, description: this.premio.descrizioneMetodoRedenzione1 });
            if (this.premio.ean2)
                this.cards.push({ ean: this.premio.ean2, description: this.premio.descrizioneMetodoRedenzione2 });
        },
        open() {},
        close() {},
        cancel() {
            flowManager.complete();
        },

        async initBarcode(element, ean) {
            const barcodeLibrary = (await import('jsbarcode')).default;
            barcodeLibrary(element)
                .EAN13(ean, {
                    flat: true,
                    fontSize: 16,
                    fontOptions: 'bold',
                    textMargin: 7,
                    height: 71,
                    width: 3,
                    text: ean,
                })
                .render();
        },
    };
}
