import { flowManager } from '../../../../../libs/flow-manager';
import './style.scss';

export default function cardistaAnteCPlus() {
    return {
        before() {

        },
        open() {

        },
        close() {

        },
        cancel() {
            flowManager.complete();

        }
    };
}
