import Component from '../../../../../libs/components/component';
import { getMyConadApiProvider } from '../../../../../libs/myconad-api-provider';
import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import './style.scss';

export default class ContainerCardPremi extends Component {
    constructor(name, root) {
        super(name, root);
        this.root = root;
        this.userService = getUserService();
        this.cards = this._dEl('card', true);
        this._doLogic();
    }

    async _doLogic() {
        const user = await this.userService.getUser();
        if (!user || !user.cartaFedelta) {
            return;
        }

        try {
            const myConadApiProvider = getMyConadApiProvider();
            const bolliniBalance = await myConadApiProvider.getUserBalance({ fidelityCard: user.cartaFedelta, collectionCode: this.root.dataset.initiativeCode });
            const totalBalance = bolliniBalance.saldoCumulato - bolliniBalance.saldoSpeso;
            if (totalBalance >= 1) {
                this.cards.forEach((card) => {
                    if (
                        card.dataset.bolliniValue &&
                        card.dataset.bolliniValue <= totalBalance
                    ) {
                        card.querySelector('.rt218-card-premi__suitable').classList.remove('rt218-card-premi__suitable--invisible');
                    }
                });
            }
        } catch (e) {
            console.warn(e);
        }
    }
}

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
    if (import.meta.webpackHot.status() == 'apply') getRegister().reload('.rt142-container-card-premi');
}
