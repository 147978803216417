import { getCurrentPopup } from '../../repository/apps/conad-corporate/templates/ru003-popup/scripts/get-popup';
import Component from './component';

export default class PopupComponent extends Component {
    constructor(name, root) {
        super(name, root);
        this._initPopup();
    }

    _initPopup() {
        this.popup = getCurrentPopup(this.root);
        this._initPopupEvents();
        this._initSteps();
    }

    _initPopupEvents() {
        this._addListener(
            'ecPopupBefore',
            () => {
                this._onBeforeOpen();
            },
            this.popup.getRoot()
        );
        this._addListener(
            'ecPopupOpen',
            () => {
                this._onOpen();
            },
            this.popup.getRoot()
        );
        this._addListener(
            'ecPopupClose',
            () => {
                this._onClose();
            },
            this.popup.getRoot()
        );
        this._addListener(
            'ecPopupCancel',
            () => {
                this._onCancel();
            },
            this.popup.getRoot()
        );
    }

    _initSteps() {}

    _onOpen() {}

    _onClose() {}

    _onCancel() {}

   async _onBeforeOpen() {}

    getPopup() {
        return getCurrentPopup(this.root);
    }
}
