import { getRegister } from '../../../../../libs/register';
import { getUserService } from '../../../../../libs/user-service';
import { getMyConadApiProvider } from '../../../../../libs/myconad-api-provider';
import './style.scss';

export default function strilloInitiative() {
    return {
        showComponent: !window.wcmmodeDisabled,
        async init() {
            this._showHideMainComponent(false); // don't show when loading
            if (!this.$root.dataset.campaignCode) {
                this._showHideMainComponent(true);

                this.showComponent = true;
                return;
            }

            const user = await getUserService().getUser();
            if (!user || !user.cartaFedelta) {
                this._removeMainComponent();
                return;
            }


            this._checkUserCredits(user);
        },

        async _checkUserCredits(user) {
            // get user credits balance
            try {
                const data = {
                    fidelityCard: user.cartaFedelta,
                    collectionCode: this.$root.dataset.initiativeCode,
                };
                const result = await getMyConadApiProvider().getUserBalance(data);
                if (result.saldoCumulato > 0) {
                    // update cta labels
                    const ctaABCmobileObj = this.$refs.ctaABCmobile ? getRegister().getClass(this.$refs.ctaABCmobile) : null;
                    if (ctaABCmobileObj) {
                        ctaABCmobileObj.setLabel(this.$root.dataset.playLabel);
                    }
                    const ctaCdesktopObj = this.$refs.ctaCdesktop ? getRegister().getClass(this.$refs.ctaCdesktop) : null;
                    if (ctaCdesktopObj) {
                        ctaCdesktopObj.setLabel(this.$root.dataset.playLabel);
                    }
                    this._showHideMainComponent(true);
                    this.showComponent = true;
                }
            } catch (error) {
                console.warn(error);
            }

            // remove if not to show
            if (!this.showComponent) {
                this._removeMainComponent();
            }
        },

        _removeMainComponent() {
            if (!window.wcmmodeDisabled) return;
            const event = new CustomEvent('removeLayoutItem', { bubbles: true });
            event.data = { remove: true };
            this.$root.dispatchEvent(event);
        },
        _showHideMainComponent(show) {
            if (!window.wcmmodeDisabled) return;
            const event = new CustomEvent('hideLayoutItem', { bubbles: true });
            event.data = { hide: !show };
            this.$root.dispatchEvent(event);
        }
    };
}
