import Component from '../../../../../libs/components/component';
import { getTrackingManager } from '../../../../../libs/tracking-manager';
import './style.scss';

export default class AfterPolicyActivation extends Component {
    constructor(name, root) {
        super(name, root);
        this.imageSuccess = this._dEl('imageSuccess');
        this.imageError = this._dEl('imageError');
        this.trackingManager = getTrackingManager();
        this.titleSuccess = this._dEl('titleSuccess');
        this.titleGenericError = this._dEl('titleGenericError');

        this.titlePolicyAlreadyActiveError = this._dEl('titlePolicyAlreadyActiveError');
        this.textSuccess = this._dEl('textSuccess');
        this.textSuccessBox = this._dEl('textSuccessBox');

        this.textGenericError = this._dEl('textGenericError');
        this.textPolicyAlreadyActiveError = this._dEl('textPolicyAlreadyActiveError');
        this.ctaSuccess = this._dEl('ctaSuccess');
        this.ctaGenericError = this._dEl('ctaGenericError');
        this.ctaPolicyAlreadyActiveError = this._dEl('ctaPolicyAlreadyActiveError');
        this._init();


    }

    _init() {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        switch (status) {
            case 'success': {
                this.imageSuccess?.classList.remove('hidden');
                this.imageSuccess?.classList.add('block');
                this.titleSuccess?.classList.remove('hidden');
                this.textSuccess?.classList.remove('hidden');
                this.textSuccessBox?.classList.remove('hidden');
                this.ctaSuccess?.classList.add(this._elMod('ctaSuccess', 'show'));

                this.trackingManager.track(this.root, {
                    event: 'pageview',
                    funnel: {
                        nomeFunnel: 'Carrello protetto',
                        stepFunnel: 'Thank you page attivazione'
                    },
                }); 
                break; 
            }
            case 'policyAlreadyActiveError': {
                this.imageError?.classList.remove('hidden');
                this.imageError?.classList.add('block'); 
                this.titlePolicyAlreadyActiveError?.classList.remove('hidden');
                this.textPolicyAlreadyActiveError?.classList.remove('hidden');
                this.ctaPolicyAlreadyActiveError?.classList.add(this._elMod('ctaPolicyAlreadyActiveError', 'show'));
                
                this.trackingManager.track(this.root, {
                    event: 'pageview',
                    funnel: {
                        nomeFunnel: 'Carrello protetto',
                        stepFunnel: 'TYP errore polizza gia attiva' 
                    },
                });   

                break;
            }
            case 'genericError':
            default: {
                this.imageError?.classList.remove('hidden'); 
                this.imageError?.classList.add('block');  
                this.titleGenericError?.classList.remove('hidden');
                this.textGenericError?.classList.remove('hidden');
                this.ctaGenericError?.classList.add(this._elMod('ctaGenericError', 'show'));
            }
        }
    }
}


